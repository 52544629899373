import {Point} from 'paper/dist/paper-core'

class Bubble {
  constructor(bubble) {
    this.bubble = bubble;
  }

  refresh(point){
    this.bubble.position = point;
    this.bubble.bringToFront();
    this.bubble.visible = true;
    this.explodedAt = null;
    this.reachedSurfaceAt = null;
  }

  update(v, t, dt, surface) {
    if(this.bubble.position.y > 80) {
      this.moveInsideWater(v, dt)
    } else {
      this.moveOnSurface(v, t, dt, surface);
    }
  }

  moveInsideWater(v, dt) {
    const {x, y} = this.bubble.position;
    this.bubble.position = new Point(
      x + v.x * dt * (2.0 - 3.0 * Math.random()),
      y - v.y * dt
    );
  }

  moveOnSurface(v, t, dt, surface) {
    const {bubble} = this;
    const newX = bubble.position.x + dt * v.x * (1.0 - 1.5 * Math.random());
    const p = new Point(
      newX,
      surface(newX, t) + 2
    );
    if(!this.reachedSurfaceAt && bubble.position.y < p.y) {
      this.reachedSurfaceAt = t;
      bubble.sendToBack();
    }
    if(this.reachedSurfaceAt) {
      if(this.explodedAt) {

      } else if(this.willExplode(t - this.reachedSurfaceAt)) {
        this.explode(t);
      } else {
        bubble.position = p;
      }
    } else {
      this.moveInsideWater(v, dt);
    }
  }

  willExplode(dt) {
    return (1 - Math.exp(- 0.1 * dt) > Math.random());
  }

  explode(explodedAt) {
    this.bubble.visible = false;
    this.explodedAt = explodedAt;
  }

  pulse(x, t) {
    if(!this.explodedAt) return 0;
    const dx = x - this.bubble.position.x;
    const dt = t - this.explodedAt;
    const tau = 0.4;
    const v = 120;
    return 0.1 * this.bubble.bounds.width * Math.exp(- dt / tau) *(
      Math.exp( - Math.pow((dx - v * dt), 2) / 100) +
      Math.exp( - Math.pow((dx + v * dt), 2) / 100));
  }
}

export default class BubbleCluster {
  constructor({view, definition, n = 10}) {
    this.pulse = this.pulse.bind(this);
    this.view = view;
    this.bubbles = [];

    this.bubbles = this.randomPoints(n).map((p) => {
      const instance = definition.place(p);
      return new Bubble(this.randomScale(instance));
    });
  }

  randomScale(instance) {
    return instance.scale(
      instance.definition.item.bounds.width * (0.6 + 0.8 * Math.random()) / instance.bounds.width
    );
  }

  randomCenter() {
    const {x, y, width, height} = this.view.bounds;
    return new Point(
      x + width * Math.random(),
      y + height + 100,
    )
  }

  randomPoints(n) {
    let points = [];
    const center = this.randomCenter();
    for(let i = 0; i < n; i++) {
      points.push(new Point(
        center.x + Math.random() * (50 + i * 10),
        center.y - (i * 10 + 10 * Math.random())
      ));
    }
    return points;
  }

  update(v, event, surface) {
    const t = event.time;
    const dt = event.delta;
    for(let bubble of this.bubbles) {
      bubble.update(v, t, dt, surface);
    }
    const allExplodedAt = this.allExplodedAt();
    if(allExplodedAt && (t - allExplodedAt) > 1) {
      this.refresh();
    }
  }

  refresh() {
    const l = this.bubbles.length;
    const points = this.randomPoints(l);
    for(let i = 0; i < l; i++) {
      if(this.bubbles[i]) {
        this.bubbles[i].refresh(points[i]);
        this.randomScale(this.bubbles[i].bubble);
      }
    }
  }

  pulse(x, t) {
    return this.bubbles.reduce((a,e) => a + e.pulse(x, t), 0);
  }

  allExplodedAt() {
    let res = 0;
    for(let bubble of this.bubbles) {
      if(!bubble.explodedAt) return null;
      res = Math.max(res, bubble.explodedAt);
    }
    return res;
  }
}
