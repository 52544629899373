import {Path, Point} from 'paper/dist/paper-core'

export default class Wave {
  constructor({view, fillColor, step, v = 0.05, amplitude = 8, lambda = 40}) {
    let path = new Path({fillColor});
    this.path = path;
    this.step = step;
    this.amplitude = amplitude;
    this.points = 0;
    this.baseY = 40;
    this.lambda = lambda;
    this.v = 100;
    this.offset = 2;
    this.path.segments = this.initialPoints(view.size.width);
  }

  initialPoints(width, t = 0) {
    const maxY = 90;
    let points = []
    const {baseY, step} = this;
    const margin = step;
    points.push(new Point(-margin, maxY));
    points.push(new Point(-margin, baseY));
    for(let x = -margin; x <= width + margin; x += step) {
      points.push(new Point(x, this.calculateY(x, t)));
    }
    points.push(new Point(width + margin, baseY));
    points.push(new Point(width + margin, maxY));
    this.movingPoints = points.length - 4;
    return points;
  }

  delta(x, t) {
    const {amplitude, lambda, v} = this;
    return amplitude * Math.sin(0.3 * (x - v * t )/ lambda) +
           0.11 * amplitude * Math.sin((x - v * t) / lambda) +
           0.13 * amplitude * Math.sin(1.3 * (x + 1.1 * v * t) / lambda);
  }

  update(t, addend) {
    const {movingPoints, path, offset} = this;
    for(let i = offset; i < movingPoints + offset; i++) {
      const seg = path.segments[i];
      seg.point.y = this.calculateY(seg.point.x, t, addend);
    }
    this.path.smooth({type: 'continuous', from: 1, to: 1 + this.movingPoints});
  }

  calculateY(x, t, addend) {
    const augend = this.baseY + this.delta(x, t);
    return addend ? augend + addend(x, t) : augend;
  }

  onResize(event, t) {
    this.path.segments = this.initialPoints(event.size.width, t);
  }
}
