import paper, {view, Path, SymbolDefinition} from 'paper/dist/paper-core'
import BubbleCluster from './bubble_cluster'
import Wave from './wave'

const DARK_COLOR = '#00464F';

const v = {
  x: 20,
  y: 120,
};

export default {
  start: (canvas) => {
    paper.setup(canvas);

    const circle = new Path.Circle({
      center: [0, 0],
      radius: 7,
      fillColor: '#BECED2',
      strokeColor: DARK_COLOR,
      strokeWidth: 2
    });
    const definition = new SymbolDefinition(circle);

    const wave = new Wave({view, step: 4, fillColor: DARK_COLOR});
    const cluster = new BubbleCluster({view, definition, n: 10});
    let t = 0;
    wave.calculateY = wave.calculateY.bind(wave);

    view.onFrame = (event) => {
      t = event.time;
      cluster.update(v, event, wave.calculateY);
      wave.update(event.time, cluster.pulse);
    }

    view.onResize = (event) => {
      wave.onResize(event, t);
    }

    view.draw();
  },
  paper
}
