import React from "react"
import { Link } from "gatsby"
import * as styles from "./navigation.module.scss"
import { StaticQuery, graphql } from "gatsby"

const Navigation = () => (
  <StaticQuery
    query={graphql`
      query SiteNavigationLinksQuery {
        site {
          siteMetadata {
            navigationLinks {
              title
              path
            }
          }
        }
      }
    `}
    render={data => (
      <nav className={styles.container}>
        <ul className={styles.list}>
          {data.site.siteMetadata.navigationLinks.map(({ title, path }) => (
            <li key={path} className={styles.item}>
              <Link
                className={styles.link}
                activeClassName={styles.linkActive}
                to={path}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    )}
  />
)

export default Navigation
