/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Navigation from "./navigation"
import * as styles from "./layout.module.scss"

import Logo from "./logo"

const Layout = ({ children }) => (
  <>
    <Logo />

    <Navigation />

    <div id="main" className={styles.main}>
      <div className={styles.contentWrapper}>
        <main className={styles.content}>{children}</main>
      </div>
      <canvas id="canvas" data-paper-resize="true" />
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
