/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import "./src/styles/application.scss"
import animation from "./src/animation"
import Layout from "./src/components/layout"

export const onInitialClientRender = () => {
  const canvas = document.getElementById("canvas")
  animation.start(canvas)
}

export const shouldUpdateScroll = () => {
  return false
}

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

export const onRouteUpdate = () => {
  const h = document.getElementById("main").offsetHeight
  if (animation.paper.view) animation.paper.view.viewSize.height = h
}
